import { MouseEvent, useCallback } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { ActionTextType, ICustomTextProps } from '@vfit/shared/models';
import { purifyTextWithLogo } from '@vfit/shared/data-access';
import { CustomTextContainer } from './customText.style';

/**
 * component to render custom text. Comply with all html standards.
 * To insert a custom button with a defined action you need to use
 * <button> 5G <span data-action = 'product'> + </span> </button>
 * specifying data-action
 * @param text
 * @param textAlign
 * @param onClickType
 * @param anchorScroll
 * @param sizeMobile
 * @param size
 * @param lineHeight
 * @param lineHeightMobile
 * @param fontFamily
 * @param modal
 * @param disableOrganizeText
 * @constructor
 */
const CustomText = ({
  text,
  textAlign,
  onClickType,
  anchorScroll,
  sizeMobile,
  size,
  lineHeight,
  lineHeightMobile,
  fontFamily,
  modal,
  margin,
  disableOrganizeText,
  dataName
}: ICustomTextProps) => {
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    // check for security issue
    // set all elements owning target to rel=noopener
    // https://developer.chrome.com/docs/lighthouse/best-practices/external-anchors-use-rel-noopener/
    if ('target' in node) {
      node.setAttribute('rel', 'noopener');
    }
  });

  const sanitizeText = DOMPurify.sanitize(text, { ADD_ATTR: ['target'] });

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const element = e.target as HTMLDivElement;
      const dataAction = element.getAttribute('data-action') as ActionTextType;
      const dataDescription = element.getAttribute('data-desc') as ActionTextType;
      const dataTitle = element.getAttribute('data-title') as ActionTextType;
      if ((dataAction || dataDescription) && onClickType && dataAction in ActionTextType) {
        onClickType(ActionTextType[dataAction], dataDescription, dataTitle);
      }
    },
    [onClickType]
  );

  return (
    <CustomTextContainer
      textAlign={textAlign || 'center'}
      dangerouslySetInnerHTML={{
        __html: disableOrganizeText ? sanitizeText : purifyTextWithLogo(sanitizeText),
      }}
      onClick={handleClick}
      id={anchorScroll ? 'anchorScroll' : ''}
      data-name={dataName?? undefined}
      sizeMobile={sizeMobile || size}
      size={size}
      lineHeight={lineHeight}
      lineHeightMobile={lineHeightMobile || lineHeight}
      fontFamily={fontFamily}
      modal={modal}
      margin={margin}
    />
  );
};

export default CustomText;

